import React from "react";
import {
  Layout,
  Section,
  SEO,
  Content,
  Counter,
  Title,
  AnimTitle
} from "components";
import styles from "./index.module.scss";
import twitter from "./twitter.png";
import facebook from "./facebook.png";

function IframePage({ ...props }) {
  return (
    <Layout noLogo>
      <SEO
        title="Følg regeringens nedskæringer på uddannelse"
        description="Regeringen har allerede skåret 6 milliarder på uddannelse siden 2015. Følg nedskæringerne live her."
        meta={[
          {
            name: `robots`,
            content: "noindex,nofollow"
          }
        ]}
      />
      <Section gradient equalPadding>
        <Content>
          <Title as="h1" large>
            <AnimTitle
              bold
              fadeText="Siden 2015 har regeringen skåret ned"
              typewriterText="på uddannelse med over 6 milliarder kroner:"
            />
          </Title>
          <Counter />
          <Title as="h3" narrow>
            <AnimTitle
              delay={6}
              typewriterText="Og nedskæringerne fortsætter."
            />
            <br />
            <AnimTitle
              delay={7.5}
              typewriterText="Hver dag bliver der skåret over 9 millioner kr."
            />
          </Title>
        </Content>
      </Section>
    </Layout>
  );
}

export default IframePage;
